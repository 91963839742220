import img from "../../images/other_services/sanificazione_.jpg";
import Service from "../../components/Service";
import {pageStyles} from '../../styles/style';
import Footer from "../../components/Footer";
import DownbarS from "../../components/down_footer_s";
import Navbar from "../../components/Navbar";
import Banner from "../../components/Banner";
import Works from "../../components/Works";
import { Helmet } from "react-helmet";
import * as React from "react";
import "../../styles/main.css";
import FloatButton from "../../components/FloatButton";

// data

// markup
const IndexPage = () => {
  return (
    <main style={pageStyles}>
      <FloatButton />
      <Helmet>
        <meta charSet="utf-8" />
        <title>Manutenpul</title>
        <meta name="keywords" content="impresa di pulizie milano, impresa di pulizie milano prezzi, impresa pulizie costi milano, impresa di pulizie cesano boscone, impresa di sanificazione, impresa di pulizie lodi" />
      </Helmet>
      <Navbar></Navbar>
      <DownbarS></DownbarS>
      <Banner pageName="Sanificazioni " prepageName="Servizio" postpageName="Sanificazioni Civili ed Industriali" />
      <Service
        title="SANIFICAZIONE"
        text="Con il Covid la sanificazione è diventato un aspetto fondamentale delle nostre vite. Manutenpul ti offre servizi di sanificazione con macchine. 
        La sanificazione è una procedura di igienizzazione profonda, diversa dalla classica pulizia. "
        img={img}
      >
      <br />
      <br />
      <p style={{color:"#000"}}>Con la sanificazione di Manutenpul viene eliminato qualsiasi agente batterico.</p>
      <br />
      <p style={{color:"#000"}}>La sanificazione non sostituisce la normale pulizia.</p>
      <br />
      <p style={{color:"#000"}}>Per la sanificazione occorrono macchinari specifici, non di uso comune</p>
      <br />
      <p style={{color:"#000"}}>E’ un processo sia per aziende che per privati. </p>
      <br />
      <p style={{color:"#000"}}>La sanificazione si può eseguire anche in un condominio abitato</p>
      </Service>
      <Works />
      <Footer></Footer>
    </main>
  );
};

export default IndexPage;
